<template>
  <div>
    <v-slide-group multiple>
      <v-slide-item>
        <v-btn :input-value="fild_active == 'all' ? true: false" 
        @click="change_sub('all')" outlined :color="fild_active == 'all' ? '': 'primary'" class="button mx-1" active-class="primary onPrimary--text">
        همه
        </v-btn>
      </v-slide-item>
      <v-slide-item
        v-for="slide  in slides" :key="slide.id"
      >
        <v-btn
          :input-value="fild_active == slide.title ? true: false"
          class="button mx-1"
          outlined
          :color="fild_active == slide.title ? '': 'primary'"
          @click="change_sub(slide)"
          v-text="slide.title"
          active-class="primary onPrimary--text"
        ></v-btn>
      </v-slide-item>
      </v-slide-group>
  </div>
</template>
<script>
import { search_product } from '../../../search/models/search'
export default {
  props: ['slides'],

  data: () => ({
    model: null,
    fild_active: 'all'
  }),
  created () {
    const title = localStorage.getItem('subordinate')
    if (title) {
      this.fild_active = title
    }
  },
  methods: {
    change_sub (sub) {
      if (sub == 'all') {
        localStorage.setItem('subordinate', 'all')
        this.fild_active = 'all'
        let filters = localStorage.getItem('filter_search')
        const category = JSON.parse(localStorage.getItem('category_id'))
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = 1
          let tages = category.tag[0]
          if (category.tag.length > 1) {
            for (const key of category.tag) {
              tages = key + ',' + tages
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
          setTimeout(() => {
            search_product(filters)
          }, 500)
        } else {
          filters = {}
          filters.page = 1
          let tages = category.tag[0]
          if (category.tag.length > 1) {
            for (const key of category.tag) {
              tages = tages + ',' + key
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
          setTimeout(() => {
          search_product(filters)
          }, 500)
        }
      } else {
        localStorage.setItem('subordinate', sub.title)
        this.fild_active = sub.title
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = 1
          let tages = sub.tag[0]
          if (sub.tag.length > 1) {
            for (const key of sub.tag) {
              tages = key + ',' + tages
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
          setTimeout(() => {
            search_product(filters)
          }, 500)
        } else {
          filters = {}
          filters.page = 1
          let tages = sub.tag[0]
          if (sub.tag.length > 1) {
            for (const key of sub.tag) {
              tages = tages + ',' + key
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
          setTimeout(() => {
          search_product(filters)
          }, 500)
        }
      }
    }
  }
}
</script>